import React, { Component, useEffect, useState } from "react";
import PageHelmet from "../component/common/Helmet";
import ModalVideo from 'react-modal-video';
import { FaTwitter, FaInstagram, FaFacebookF, FaLinkedinIn, FaGooglePlay, FaGlobe, FaGithub } from "react-icons/fa";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import FooterTwo from "../component/footer/FooterTwo";
import { useLoaderData, useNavigate } from "react-router-dom";
import { FcGlobe } from "react-icons/fc";
import { GoGlobe } from "react-icons/go";

// var SocialShare = [
//     { Social: <FaFacebookF />, link: 'https://www.facebook.com/' },
//     { Social: <FaLinkedinIn />, link: 'https://www.linkedin.com/' },
//     { Social: <FaInstagram />, link: 'https://www.instagram.com/' },
//     { Social: <FaTwitter />, link: 'https://twitter.com/' },
// ]



const PortfolioDetails = () => {
    const [isOpen, setIsOpen] = useState(false)
    const navigation = useNavigate()

    const [project, ...more] = useLoaderData()

    
    const [socialShare, setSocialShare] = useState([])

    useEffect(() => {
        if(!project){
            navigation("/error",{replace : true})
        }
        var share = []
        if (project.projectLink && project.projectLink.length > 0) {
            share.push({ Social: <GoGlobe />, link: project.projectLink, title: "Project Site" },)
        }
        if (project.playLink && project.playLink.length > 0) {
            share.push({ Social: <FaGooglePlay />, link: project.playLink, title: "Fiche play store" },)
        }
        if (project.githubLink && project.githubLink.length > 0) {
            share.push({ Social: <FaGithub />, link: project.githubLink, title: "Code Source" },)
        }

        setSocialShare(share)
    }, [project])

    return (
        <div className="">
            {project&& <React.Fragment >
                <PageHelmet pageTitle='Portfolio Details' />

                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />

                {/* Start Breadcrump Area */}
                <div className="rn-page-title-area pt--120 pb--190 bg_image bg_image--4" data-black-overlay="7">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="rn-page-title text-center pt--100">
                                    <h2 className="title theme-gradient">{project.title}</h2>
                                    <p>{project.domain}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Breadcrump Area */}

                {/* Start Portfolio Details */}
                <div className="rn-portfolio-details ptb--120 bg_color--1">
                    <div className="container">
                        <div className="lg-row">
                            <div className="col-lg-12">
                                <div className="portfolio-details">
                                    <div className="inner">
                                        <div className="mb-2">
                                            <h5 >{project.start} - {project.end}</h5>
                                        </div>
                                        <div className="row align-items-center">
                                            {project.logo && <img src={`/assets/images/me/${project.logo}`} className="mb-5 col-md-2 col-sm-2 img-fluid col-2" />}
                                            <h2 className="col ">{project.title}</h2>
                                        </div>

                                        <p className="subtitle">{project.subtitle}</p>

                                        {/* <div className="thumbnail w-50 px-2 py-3 bg-dark ">

                                            <img className="w-100" src={`/assets/images/me/${project.bg}`} alt="Project Images" />

                                        </div> */}
                                        {project.description.map((d) => {
                                            if (d.includes("-")) {
                                                return <p><ul>
                                                    {d.split("-").map((l) => {
                                                        if (l.includes(':')) {
                                                            const ll = l.split(':')
                                                            return <li><strong>{ll[0]}</strong> : {ll[1]}</li>
                                                        } else {
                                                            return <li><strong>{l}</strong></li>
                                                        }
                                                    })}
                                                </ul></p>
                                            } else {
                                                return <p>{d}</p>
                                            }
                                        })}


                                        <div className="portfolio-view-list d-flex flex-wrap">
                                            {project.contributors && project.contributors.length > 0 && <div className="port-view">
                                                <span>En équipe avec</span>
                                                {project.contributors.map((c) => <h4>{c}</h4>)}

                                            </div>}

                                            {/* <div className="port-view">
                                                <span>Project Types</span>
                                                <h4>Website</h4>
                                            </div>

                                            <div className="port-view">
                                                <span>Program</span>
                                                <h4>View Project</h4>
                                            </div> */}
                                        </div>

                                        <div className="portfolio-share-link mt--20 pb--70 pb_sm--40">
                                            <ul className="social-share rn-lg-size d-flex justify-content-start liststyle mt--15">
                                                {socialShare.map((val, i) => (
                                                    <li key={i}><a href={`${val.link}`} title={val.title} target="_blank">{val.Social}</a></li>
                                                ))}
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="portfolio-thumb-inner text-center row bg-light p-md-5 rounded-4">
                                        {project.illustrations.map((im) => {
                                            return (
                                                <div className="thumb mb--30 col-md-6">
                                                    <img src={`/assets/images/me/${im}`} alt="Portfolio Images" />
                                                </div>
                                            )
                                        })}

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Portfolio Details */}

                {/* Start Related Work */}
                <div className="portfolio-related-work pb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center">

                                    <h2>Plus de Projets</h2>
                                </div>
                            </div>
                        </div>
                        <div className="row mt--10">
                            {/* Start Single Portfolio */}

                            {more.map((p) => (
                                <div className="col-lg-6 col-md-6 col-12">
                                    <div className="related-work text-center mt--30">
                                        <div className="thumb">
                                            <a href={`/portfolio-details/${p[1]}`}>
                                                <img src={`/assets/images/me/${p[0].bg}`} alt="Portfolio-images" />
                                            </a>
                                        </div>
                                        <div className="inner">
                                            <h4><a href={`/portfolio-details/${p[1]}`}>{p[0].title}</a></h4>
                                            <span className="category">{p[0].domain}</span>
                                        </div>
                                    </div>
                                </div>
                            ))}

                            {/* End Single Portfolio */}

                        </div>
                    </div>
                </div>
                {/* End Related Work */}

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}

                <FooterTwo />


            </React.Fragment> }
        </div>
    )

}
export default PortfolioDetails;
