import React, { Component ,Fragment } from "react";

import fr from "../../assets/json/i12n/myCv/fr.json"
import GraduationCard from "../compents/GraduationCard";

class GraduationsList extends Component{
    render() {
        
        return (
             <Fragment>
                 <div className="row">
                    {fr.diplomas.map((value , i ) => (
                        <div className="col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
                            <GraduationCard graduation={value}/>
                        </div>
                     ))}
                 </div>
             </Fragment>
        );
    }
}
export default GraduationsList;