import React, { Component, useRef, useState } from "react";
import * as EmailValidator from 'email-validator';
import axios from "axios"

const ContactThree = ({ contactImages, contactTitle }) => {

    const [data, setData] = useState({ name: "", email: "", subject: "", content: "" })

    const ref1 = useRef()
    const ref2 = useRef()
    const ref3 = useRef()
    const ref4 = useRef()
    const list = [ref1,ref2,ref3,ref4]

    const [error, setError] = useState()
    const [sending, setSending] = useState(false)
    const [sent, setSent] = useState(false)
    const handleChange = (key, value) => {
        var tmp = data;
        tmp[key] = value;
        setData(tmp)
        
    }

    const send = async () => {
        
        if (data.name === "") {
            setError("Nom vide !!")
        }
        else if (EmailValidator.validate(data.email)) {
            setSending(true)
            setError(undefined)
            const response = await axios.post("https://me.dinyad.fr/hireme",data);
            if(response.data && response.data.result == "sent"){
                setSending(false)
                setSent(true)

                list.forEach(ref => {
                    ref.current.value="";
                });
               
            }else{
                setSending(false)
                setError("Désolé, erreur d'envoie, veuillez réessayer...")
            }

        } else {

            setError("Email non valide")

        }

    }

    return (
        <div className="contact-form--1">
            <div className="container">
                <div className="row row--35 align-items-start">
                    <div className="col-lg-6 order-2 order-lg-1">
                        <div className="section-title text-left mb--50">
                            <h2 className="title">{contactTitle}</h2>
                            <p className="description">Je suis disponible pour un travail. Contactez-moi par téléphone : <a href="tel:+33610241133"> 0610241133</a> ou par e-mail :
                                <a href="mailto:donatien.yeto@dinyad.fr"> donatien.yeto@dinyad.fr</a> ou simplement laissez un message ici... </p>
                        </div>
                        {error &&
                            <div className="section-title text-left mb-2">

                                <p className="description text-danger">
                                    {error}
                                </p>
                            </div>
                        }

                        {sent &&
                            <div className="section-title text-left mb-2">

                                <p className="description text-success">
                                    Envoyé avec succès, Merci ! <br/>
                                    Dinyad vous recontactera prochainement.
                                </p>
                            </div>
                        }

                        <div className="form-wrapper">
                            <form>
                                <label htmlFor="item01">
                                    <input
                                        type="text"
                                        name="name"
                                        id="item01"
                                        defaultValue={data.name}
                                        
                                        onChange={(e) => { handleChange("name", e.target.value); }}
                                        placeholder="Your Name *"
                                        ref={ref1}
                                    />
                                </label>

                                <label htmlFor="item02">
                                    <input
                                        type="text"
                                        name="email"
                                        id="item02"
                                        defaultValue={data.email}
                                        onChange={(e) => { handleChange("email", e.target.value); }}
                                        placeholder="Your email *"
                                        ref={ref2}
                                    />
                                </label>

                                <label htmlFor="item03">
                                    <input
                                        type="text"
                                        name="subject"
                                        id="item03"
                                        defaultValue={data.subject}
                                        onChange={(e) => { handleChange("subject", e.target.value); }}
                                        placeholder="Write a Subject"
                                        ref={ref3}
                                    />
                                </label>
                                <label htmlFor="item04">
                                    <textarea
                                        type="text"
                                        id="item04"
                                        name="content"
                                        defaultValue={data.content}
                                        onChange={(e) => { handleChange("content", e.target.value); }}
                                        placeholder="Your Message"
                                        ref={ref4}
                                    />
                                </label>
                                <button onClick={send} disabled={sending} className="rn-button-style--2 btn-solid" type="button" defaultValue="submit" name="submit" id="mc-embedded-subscribe">
                                    {
                                        !sending &&
                                        "Envoyer"
                                    }
                                    {
                                        sending && <div class="spinner-border text-primary" role="status">
                                            <span class="sr-only"></span>
                                        </div>
                                    }


                                </button>
                            </form>
                        </div>
                    </div>
                    <div className="col-lg-6 order-1 order-lg-2">
                        <div className="thumbnail mb_md--30 mb_sm--30">
                            <img src={contactImages} alt="me.Dinyad" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

}

export default ContactThree;