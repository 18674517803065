import React, { Component, Fragment } from "react";
import { Parallax } from "react-parallax";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import { slickDot } from "../page-demo/script";

import Header from "../component/header/Header";
import ServiceList from "./elements/ServiceList";
import BlogContent from "../elements/blog/BlogContent";
import BrandTwo from "../elements/BrandTwo";
import FooterTwo from "../component/footer/FooterTwo";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Helmet from "../component/common/Helmet";
import HeaderThree from "./elements/HeaderThree";
import PortfolioList from "../elements/portfolio/PortfolioOne";
import listIcons from "../component/icons/list"


const image1 =
    "/assets/images/bg/paralax/bg-image-3-overlay.jpg";

const SlideList = [
    {
        textPosition: 'text-center',
        category: '',
        title: 'Dinyad',
        description: 'There are many variations of passages of Lorem Ipsum available but the majority have suffered alteration.',
        buttonText: 'Donatien Yeto',
        buttonLink: 'https://me.dinyad.fr'
    }
]

const list = [
    {
        image: 'image-1',
        category: 'Development',
        title: 'Getting tickets to the big show'
    },
    {
        image: 'image-2',
        category: 'Development',
        title: 'Getting tickets to the big show'
    },
    {
        image: 'image-3',
        category: 'Development',
        title: 'Getting tickets to the big show'
    },
    {
        image: 'image-4',
        category: 'Development',
        title: 'Getting tickets to the big show'
    },
    {
        image: 'image-3',
        category: 'Development',
        title: 'Getting tickets to the big show'
    },
    {
        image: 'image-4',
        category: 'Development',
        title: 'Getting tickets to the big show'
    }
]

class Home extends Component {

    render() {
        const PostList = BlogContent.slice(0, 5);
        return (
            <div className="active-dark">
                <Fragment>
                    <Helmet pageTitle="Dinyad" />
                    <HeaderThree homeLink="/" logo="symbol-light" color="color-black" />
                    {/* Start Slider Area   */}
                    <div className="slider-activation slider-creative-agency">
                        <Parallax bgImage={image1} strength={1000}>
                            {SlideList.map((value, index) => (
                                <div className="slide slide-style-2 slider-paralax d-flex align-items-center justify-content-center" key={index}>
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <div className={`inner ${value.textPosition}`}>
                                                    {value.category ? <span>{value.category}</span> : ''}
                                                    {value.title ? <h1 className="title theme-gradient text-capitalize text-white" style={{ backgroundColor: "linear-gradient(145deg, #f801f8 0%, #6707ee 100%)" }} >{value.title}</h1> : ''}

                                                    {/* <TextLoop className="description" delay={3000} interval> */}

                                                    <p className="description">Dinyad projette vous accompagner à vous orienter vers les formations et métiers du numérique, ou à numériser votre entreprise à  travers ses produits et services.</p>

                                                    {
                                                   /* <div><p className="description">Aujourd’hui, tout le monde parle du numérique : les médias, les réseaux sociaux, le gouvernement.</p></div>
                                                   <div><p className="description">L’école devient numérique avec le développement de l’utilisation de tablettes en classe, la découverte des robots et du code pour les faire fonctionner.</p></div>
                                                   <div><p className="description">On propose à ton petit frère de s’y former, à ta grand-mère aussi.</p></div>
                                                   <div><p className="description">Ne pas en maîtriser les usages peut se révéler handicapant tant le numérique s’est répandu dans nos vies et dans tous les secteurs d’activité.</p></div>
                                                        
                            </TextLoop>*/}
                                                    {value.buttonText ? <div className="slide-btn"><a className="rn-button-style--2 btn-primary-color text-capitalize" href={`${value.buttonLink}`}>{value.buttonText}</a></div> : ''}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </Parallax>
                    </div>
                    {/* End Slider Area   */}

                    {/* Start Service Area  */}

                    <div className="service-area creative-service-wrapper ptb--120 bg_color--1">
                        <div className="container">
                            <div className="row creative-service">
                                <div className="col-lg-12">
                                    <ServiceList item="6" column="col-lg-4 col-md-6 col-sm-6 col-12 text-left" />
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* End Service Area  */}

                    {/* Start Portfolio Area */}
                    <div id="portfolio" className="fix">
                        <div className="portfolio-area ptb--120 bg_color--1">
                            <div className="portfolio-sacousel-inner">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="section-title text-center service-style--3 mb--30 mb_sm--0">
                                                <h2 className="title">Mes Derniers Projets</h2>
                                                <p>Voici une sélection de nos derniers projets, cliquez sur chaque projet pour découvrir les détails</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <PortfolioList styevariation="text-center mt--40" column="col-lg-4 col-md-6 col-sm-6 col-12" item="6" />
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="view-more-btn mt--60 mt_sm--30 text-center">
                                                <a className="rn-button-style--2 btn-solid" href="/projects"><span>View More</span></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* End Portfolio Area */}



                    {/* Start Brand Area */}
                    <div className="rn-brand-area brand-separation bg_color--5 ptb--120">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <ul className="brand-style-2">
                                        {Object.keys(listIcons).map((k) => {
                                            const Cur = listIcons[k]
                                            if (k == "matplotlib")
                                                return <></>
                                            return <li><Cur size={50} color="purple" /></li>
                                        })}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* End Brand Area */}

                    {/* Start Footer Style  */}
                    <FooterTwo />
                    {/* End Footer Style  */}
                    {/* Start Back To Top */}
                    <div className="backto-top">
                        <ScrollToTop showUnder={160}>
                            <FiChevronUp />
                        </ScrollToTop>
                    </div>
                    {/* End Back To Top */}

                </Fragment>
            </div>
        )
    }
}

export default Home;