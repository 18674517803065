import React, { Component } from "react";
import { FiCast, FiLayers, FiUsers, FiMonitor, FiPhone, FiServer, FiTablet, FiRotateCcw, FiRotateCw, FiCornerUpRight, FiLock, FiDatabase, FiSidebar } from "react-icons/fi";
import { FaReact, FaNode, FaNodeJs, FaSymfony, FaLaravel } from "react-icons/fa"
import StarRatings from "react-star-ratings";
import fr from "../../assets/json/i12n/myCv/fr.json"
import listIcons from "../../component/icons/list"
const ServiceList = [
    {
        icon: <FiCast />,
        title: 'Développement Web',
        description: 'Pour restée visirce, blog, forum, institutionnel, communautaire…'
    }]



class BiblioPython extends Component {
    render() {
        const { column } = this.props;
       

        return (
            <React.Fragment>
                <div className="row">
                    {fr.topBilioPython.libraries.map((val, i) => {
                        const Cur = listIcons[val.icon];
                       return  <div className={`${column}`} key={i}>
                            
                                <div className="service service__style--2 text-center">
                                    <div className="icon">
                                        <Cur size={100}/>
                                    </div>
                                    <h3>{val.name}</h3>
                                    <div>
                                        <StarRatings starDimension="25px" rating={val.stars} starRatedColor="orange"/>
                                    </div>
                                    <div className="content mt-2">
                                        
                                        <p>_ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _</p>
                                    </div>
                                </div>
                            
                        </div>
    })}
                </div>
            </React.Fragment>
        )
    }
}
export default BiblioPython;
