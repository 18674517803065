import { useState } from "react"
import StarRatings from "react-star-ratings"
const TechCard = ({ tech }) => {
   
    return (
        <div className="bg-success rounded-pill m-2 p-1 px-3 opacity-50 fw-bold text-white text-center" 
         >
            <div className="">
                {tech}
            </div>
            
        </div>
    )
}

export default TechCard