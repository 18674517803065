import React, { Component ,Fragment } from "react";

import fr from "../../assets/json/i12n/myCv/fr.json"
import CertificationCard from "../compents/CertificationCard";
import GraduationCard from "../compents/GraduationCard";

class CertificationList extends Component{
    render() {
        
        return (
             <Fragment>
                 <div className="row justify-content-center">
                    {fr.certifications.map((value , i ) => (
                        <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt-3" key={i}>
                            <CertificationCard certification={value}/>
                        </div>
                     ))}
                 </div>
             </Fragment>
        );
    }
}
export default CertificationList;