import React, { Component ,Fragment } from "react";

import fr from "../../assets/json/i12n/myCv/fr.json"
import TrainingCard from "../compents/TrainingCard";

class TrainingList extends Component{
    render() {
        
        return (
             <Fragment>
                 <div className="">
                    {fr.studiesBySchool.map((school , i ) => (
                        <div className="py-4" key={i} id={`std${i+1}`}>
                            <div className="row">
                                <div className="thumbnail col-md-4 justify-content-center text-center">
                                    <a href="" className="">
                                        <img className="m-3 w-50 img-thumbnail rounded-circle" src={`/assets/images/me/${school.logo}`} alt="Ecole Images"/>
                                    </a>
                                    <h3 className="mt-2">{school.school}</h3>
                                    <h6>{school.location}</h6>

                                </div>
                                <div className="content col">
                                   {school.studies.map((study)=><TrainingCard training={study}/>)}
                                </div>
                            </div>
                            <div class="hr"></div>
                        </div>
                     ))}
                 </div>
             </Fragment>
        );
    }
}
export default TrainingList;