import { useState } from "react"
import StarRatings from "react-star-ratings"
const TrainingCard = ({ training }) => {
    const [showStars, setShowStars] = useState(false)
    return (
        <div className="bg-success rounded-4 m-2 p-1 px-3 opacity-50 fw-bold text-white" >
            <h3 >{training.title}</h3>
            <h5>{training.start} - {training.end}</h5>
            <h6>{training.option}</h6>
        </div>
    )
}

export default TrainingCard