import { useState } from "react"
import { TbCertificate } from "react-icons/tb"
import StarRatings from "react-star-ratings"
const CertificationCard = ({ certification }) => {
    const [showStars, setShowStars] = useState(false)
    return (





        <a href={certification.link}> <div className="service service__style--2 text-center bg-dark">
            <div className="icon">
                <TbCertificate size={100} />
            </div>
            <h3 color="purple">{certification.name}</h3>
            <div className="content mt-2">
                <h5>{certification.issuedBy}</h5>
                <h6>{certification.date}</h6>

            </div>

        </div>
        </a>
    )
}

export default CertificationCard