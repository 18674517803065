import React, { Component } from "react";
import PageHelmet from "../../component/common/Helmet";
import Breadcrumb from "../../elements/common/Breadcrumb";
import Pagination from "../../elements/common/Pagination";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../../component/header/Header";
import Footer from "../../component/footer/FooterThree";
import ProjectsList from "../lists/PorjectsList";
import CallAction from "../../elements/callaction/CallAction";
import FooterTwo from "../../component/footer/FooterTwo";

class Projects extends Component{
    render(){
        return(
            <React.Fragment>
                <PageHelmet pageTitle='Mes projets' />

                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />
                {/* Start Breadcrump Area */}
                <Breadcrumb title={'Mes projets'}   />
                {/* End Breadcrump Area */}


                {/* Start Blog Area */}
                <div className="rn-blog-area ptb--120 bg_color--1">
                    <div className="container">
                        <ProjectsList/>
                        
                    </div>
                </div>
                {/* End Blog Area */}
                
                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
                {/* <CallAction/> */}
                <FooterTwo /> 

            </React.Fragment>
        )
    }
}
export default Projects;
