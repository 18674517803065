import { useState } from "react"
import StarRatings from "react-star-ratings"
const CompKey = ({ comp }) => {
    const [showStars, setShowStars] = useState(false)
    return (
        <div className="bg-success rounded-pill m-2 p-1 px-3 opacity-50 fw-bold text-white text-center" 
        style={{cursor: "pointer"}} onClick={()=>setShowStars(!showStars)}>
            <div className="">
                {comp.name}
            </div>
            {showStars && <div>
                <StarRatings starDimension="10px" rating={comp.stars} starRatedColor="orange" starSpacing="1px" />
            </div>}
        </div>
    )
}

export default CompKey