import React, { Component } from "react";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { FiCheck, FiMail } from "react-icons/fi";

import { GoLocation } from "react-icons/go";
import fr from "../../assets/json/i12n/myCv/fr.json"
import { FaGithub, FaLinkedin, FaPhone } from "react-icons/fa";

class TabsOne extends Component {
    render() {
        let
            tab1 = "Mes domaines",
            tab2 = "Mes contacts"

        const { tabStyle } = this.props;
        var namesItemOne = [
            'The Philosophy Of business analytics',
            'Fast-Track Your business',
            'Lies And Damn Lies About business',

        ];

        return (
            <div>
                {/* Start Tabs Area */}
                <div className="tabs-area">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <Tabs>
                                    <TabList className={`${tabStyle}`}>
                                        <Tab>{tab1}</Tab>
                                        <Tab>{tab2}</Tab>

                                    </TabList>




                                    <TabPanel>
                                        <div className="single-tab-content">
                                            <p>{fr.domains.description}</p>

                                            <div className="mt--30">
                                                <h4>{fr.domains.title}</h4>
                                                <ul className="list-style--1">
                                                    {fr.domains.domaines.map((name, index) => {
                                                        return <li key={index}><FiCheck /> {name}</li>;
                                                    })}
                                                </ul>
                                            </div>
                                        </div>
                                    </TabPanel>
                                    <TabPanel>
                                        <div className="single-tab-content text-white">
                                            <div className="row align-content-center">
                                                <GoLocation color="#8b10f6" size={40} className="my-1 col-1 p-0" />
                                                <div className="ml-0 col">
                                                    <div className="row ">
                                                        <strong>{fr.contacts.address.address}</strong><br />
                                                        <strong>{fr.contacts.address.zipCode} {fr.contacts.address.city}, {fr.contacts.address.country}</strong>
                                                    </div>
                                                </div>
                                            </div>
                                            <a href={fr.contacts.linkdin} target="_blank" className=" text-white">
                                                <div className="row align-content-center my-4">
                                                    <FaLinkedin color="#8b10f6" size={20} className="mt-1 col-1 p-0" />
                                                    <strong className="col">Profil Linkdin</strong>
                                                </div>
                                            </a>
                                            <a href={fr.contacts.github} target="_blank" className=" text-white">
                                                <div className="row align-content-center my-4">
                                                    <FaGithub color="#8b10f6" size={20} className="mt-1 col-1 p-0" />
                                                    <strong className="col">Profil ithub</strong>
                                                </div>
                                            </a>
                                            <a href={"mailto:" + fr.contacts.email} target="_blank"  className=" text-white" type="f">
                                                <div className="row align-content-center my-4">
                                                    <FiMail color="#8b10f6" size={20} className="mt-1 col-1 p-0" />
                                                    <strong className="col">Mon email</strong>
                                                </div>
                                            </a>
                                            <a href={"tel:" + fr.contacts.phone} target="_blank" className=" text-white">
                                                <div className="row align-content-center mt-2">
                                                    <FaPhone color="#8b10f6" size={20} className="mt-1 col-1 p-0" />
                                                    <strong className="col">Téléphone</strong>
                                                </div>
                                            </a>
                                        </div>
                                    </TabPanel>



                                </Tabs>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Tabs Area */}
            </div>
        )
    }
}



export default TabsOne;