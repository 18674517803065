import { useState } from "react"
import StarRatings from "react-star-ratings"
const QuickLink = ({ name, link }) => {
    
    return (
        <a href={link}><div className="bg-info rounded-pill m-2 p-2 px-3 opacity-50 fw-bold text-white text-center"
            >
            <div className="text">
                <strong>{name}</strong>
            </div>

        </div>
        </a>
    )
}

export default QuickLink