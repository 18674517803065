import React, { Component } from "react";
import PageHelmet from "../../component/common/Helmet";
import Breadcrumb from "../../elements/common/Breadcrumb";

import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../../component/header/Header";
import Footer from "../../component/footer/FooterThree";
import CompetencesList from "../lists/CompetencesList";
import CallAction from "../../elements/callaction/CallAction";
import FooterTwo from "../../component/footer/FooterTwo";
import fr from "../../assets/json/i12n/myCv/fr.json"
import CompKey from "../compents/CompKey";
import TechCard from "../compents/TechCard";

class Experiences extends Component {
    render() {
        return (
            <div className="active-dark">
                <React.Fragment>
                    <PageHelmet pageTitle='Experiences' />

                    <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />
                    {/* Start Breadcrump Area */}
                    <Breadcrumb title={'Experiences'} />
                    {/* End Breadcrump Area */}


                    {/* Start Blog Area */}
                    <div className="rn-blog-area ptb--120 bg_color--1">
                        <div className="container">
                            <h3>{fr.experiences[0].post}</h3>
                            <h4>{fr.experiences[0].company}</h4>
                            <h4>{fr.experiences[0].start} - {fr.experiences[0].end}</h4>
                            <h4>{fr.experiences[0].location}</h4>
                            <h3 className="mt-5">Technologies et outils</h3>
                            <div className="col-md-8">
                                <div className="d-flex align-content-end  flex-wrap">
                                    {fr.experiences[0].usedTech.map((sk) => (
                                        <div className="">
                                            <TechCard tech={sk}/>
                                        </div>
                                    ))}
                                </div>
                            </div>

                            <h3 className="mt-5">Missions</h3>

                            <div className="col-md-8">
                                <ul>
                                    {fr.experiences[0].assignments.map((as)=><li className="fs-5 text-white">{as}</li>)}
                                </ul>
                            </div>


                        </div>
                    </div>
                    {/* End Blog Area */}

                    {/* Start Back To Top */}
                    <div className="backto-top">
                        <ScrollToTop showUnder={160}>
                            <FiChevronUp />
                        </ScrollToTop>
                    </div>
                    {/* End Back To Top */}
                    {/* <CallAction/> */}
                    <FooterTwo />

                </React.Fragment></div>
        )
    }
}
export default Experiences;
