import {
    createBrowserRouter,
    createRoutesFromElements,
    Route,
    Routes,
} from "react-router-dom";

import AboutTwo from "../component/HomeLayout/homeOne/AboutTwo";
import PortfolioOne from "../elements/portfolio/PortfolioOne";
import PortfolioDetails from "../elements/PortfolioDetails";
import Home from "../me/Home";
import fr from "../assets/json/i12n/myCv/fr.json"
import ProjectsList from "../me/lists/PorjectsList";
import Projects from "../me/elements/Projects";
import  Home2 from "../dinyad/Home";
import Competences from "../me/elements/Competences";
import Training from "../me/elements/Training";
import Experiences from "../me/elements/Experiences";
import Error404 from "../elements/error404";
import Certifications from "../me/elements/Certifications";
const loader = ({params})=>{
    return [ fr.projects[params.id-1],[fr.projects[(params.id)%fr.projects.length],(params.id)%fr.projects.length+1],[fr.projects[(params.id+1)%fr.projects.length],(params.id+1)%fr.projects.length+1] ]
}
const router = createBrowserRouter( 
    createRoutesFromElements(
        <Route>
            <Route path='/' element={<Home />} />
            <Route path='/dinyad' element={<Home2 />} />
            <Route path='/portfolio-details/:id' element={<PortfolioDetails />} loader={loader} />
            <Route path="/projects" element={<Projects/>} />
            <Route path="/competences" element={<Competences/>} />
            <Route path="/studies" element={<Training/>} />
            <Route path="/experiences" element={<Experiences/>} />
            <Route path="/certifications" element={<Certifications/>} />
            <Route path="/*" element={<Error404/>} />
        </Route>,
    )

);

export default router

// 959x874