import React from 'react';
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Helmet from "../component/common/Helmet";
import { TextLoop } from "react-text-loop-next";
import HeaderThree from "../component/header/HeaderThree";
import Header from '../component/header/Header';
import FooterTwo from "../component/footer/FooterTwo";
import TabTwo from "../elements/tab/TabTwo";
import TabOne from "../elements/tab/TabOne"
import TabThree from "../elements/tab/TabThree"
import ContactThree from "../elements/contact/ContactThree";
import PortfolioList from "../elements/portfolio/PortfolioOne";
import ServiceList from "../elements/service/ServiceList";
import BlogContent from "../elements/blog/BlogContent";

import fr from "../assets/json/i12n/myCv/fr.json"
import Globe from 'react-globe.gl';
import { SizeMe } from 'react-sizeme';
import BiblioPython from '../elements/bibliopython/BiblioPython';
import QuickLink from './compents/QuickLink';
const N = 300;
const gData = [...Array(N).keys()].map(() => ({
    lat: (Math.random() - 0.5) * 180,
    lng: (Math.random() - 0.5) * 360,
    size: Math.random() / 3,
    color: ['red', 'white', 'blue', 'green'][Math.round(Math.random() * 3)]
}));



const SlideList = [
    {
        textPosition: 'text-left',
        category: 'Bienvenue dans mon monde',
        description: '',
        buttonText: '',
        buttonLink: ''
    }

]
const Home = () => {
    let title = 'A propos',
        description = 'Développeur Web et Mobile, et aussi passionné par l\'IA, je suis actuellement élève ingénieur en informatique. J\'aime travailler en équipe etj\'ambitionne mettre mes compétences au service d\'une entreprise à la culture innovante, en développement des logiciels informatiques ainsi que leur sécurité.';
    const PostList = BlogContent.slice(0, 3);
    return (
        <div className="active-dark">
            <Helmet pageTitle="Donatien Dinyad Yeto" />

            <HeaderThree homeLink="/" logo="symbol-dark" color="color-black" />
            {/* Start Slider Area   */}
            {/* <div className='container-fluid' style={{height:400}}>
                <SizeMe >
                    {({ size }) => (
                        <Globe
                            globeImageUrl="//unpkg.com/three-globe/example/img/earth-night.jpg"
                            pointsData={gData}
                            pointAltitude="size"
                            pointColor="color"
                            width={size.width}
                            height={size.width * 3 / 4}


                        />
                    )}
                </SizeMe>
                
            </div> */}

            <div id="home" className="fix">
                <div className="slider-wrapper">

                    {/* Start Single Slide */}
                    {SlideList.map((value, index) => (
                        <div className="slide personal-portfolio-slider slider-paralax slider-style-3 d-flex align-items-center justify-content-center bg_image bg_image--25" key={index}>
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className={`inner ${value.textPosition}`}>
                                            {value.category ? <span>{value.category}</span> : ''}

                                            <h2 className="title">{fr.profile.firstname} {fr.profile.lastname}</h2>
                                            <h1 className='title'>
                                                <TextLoop adjustingSpeed={1000}>
                                                    {fr.profile.titles.map((m) => <span>{m}</span>)}
                                                </TextLoop>{" "}
                                            </h1>

                                            {/* <h3>Informatique</h3>
                         */}
                                            <p className="description text-white">{fr.profile.about2}</p>
                                            <div className="slide-btn"><a className="rn-button-style--2 btn-primary-color" href="#contact">Me contacter</a></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                    {/* End Single Slide */}
                </div>
            </div>
            {/* End Slider Area   */}

            {/* Start About Area */}
            <div id="about" className="fix">
                <div className="about-area ptb--120  bg_color--1">
                    <div className="about-wrapper">
                        <div className="container">
                            <div className="row row--35 align-items-center">
                                <div className="col-lg-5">
                                    <div className="thumbnail">
                                        <img className="w-100" src="/assets/images/about/about-8.jpg" alt="About Images" />
                                    </div>
                                </div>
                                <div className="col-lg-7">
                                    <div className="about-inner inner">
                                        <div className="section-title">
                                            <h2 className="title">A propos</h2>
                                            <p className="description">{fr.profile.about}</p>
                                        </div>
                                        <div className="row mt--30">
                                            <TabOne tabStyle="tab-style--1" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* End About Area */}

            {/* Start Service Area  */}
            <div className="fix">
                <div className="service-area creative-service-wrapper ptb--120 bg_color--5" >
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center service-style--3 mb--30 mb_sm--0">
                                    <h2 className="title">Compétences Frameworks</h2>
                                    <p>{fr.topFramewords.description}</p>
                                </div>
                            </div>
                        </div>
                        <div className="row creative-service">
                            <div className="col-lg-12">
                                <ServiceList item="6" column="col-lg-4 col-md-6 col-sm-6 col-12 text-left" />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="view-more-btn mt--60 mt_sm--30 text-center">
                                    <a className="rn-button-style--2 btn-solid" href="/competences"><span>Voir plus</span></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* End Service Area  */}

            {/* Start Service Area  */}
            <div className="fix">
                <div className="service-area creative-service-wrapper ptb--120 bg_color--5" >
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center service-style--3 mb--30 mb_sm--0">
                                    <h2 className="title">Compétences IA Python</h2>
                                    <p>{fr.topBilioPython.description}</p>
                                </div>
                            </div>
                        </div>
                        <div className="row creative-service">
                            <div className="col-lg-12">
                                <BiblioPython item="6" column="col-lg-4 col-md-6 col-sm-6 col-12 text-left" />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="view-more-btn mt--60 mt_sm--30 text-center">
                                    <a className="rn-button-style--2 btn-solid" href="/competences"><span>Voir plus</span></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* End Service Area  */}

            {/* Start Portfolio Area */}
            <div id="portfolio" className="fix">
                <div className="portfolio-area ptb--120 bg_color--1">
                    <div className="portfolio-sacousel-inner">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="section-title text-center service-style--3 mb--30 mb_sm--0">
                                        <h2 className="title">Mes Derniers Projets</h2>
                                        <p>Voici une sélection de nos derniers projets, cliquez sur chaque projet pour découvrir les détails.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <PortfolioList styevariation="text-center mt--40" column="col-lg-4 col-md-6 col-sm-6 col-12" item="6" />
                            </div>
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="view-more-btn mt--60 mt_sm--30 text-center">
                                        <a className="rn-button-style--2 btn-solid" href="/projects"><span>Voir plus</span></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* End Portfolio Area */}

            {/* Start Blog Area */}
            <div className="fix">
                <div className="rn-blog-area ptb--120 bg_color--5 mb-dec--30">
                    <div className="container">
                        <div className="row align-items-end">
                            <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                                <div className="section-title text-center">
                                    <h2>Mes études</h2>
                                </div>
                            </div>
                        </div>
                        <div className="row mt--60 mt_sm--40">
                            {fr.studiesBySchool.map((value, i) => (
                                <div className="col-lg-4 col-md-6 col-12" key={i}>
                                    <div className="blog blog-style--1">
                                        <div className="thumbnail">
                                            <a href={`/studies#std${i + 1}`}>
                                                <img className="w-100" src={`/assets/images/portfolio/portfolio-2.jpg`} alt="Blog Images" />
                                            </a>
                                        </div>
                                        <div className="content">
                                            <img className=" w-25 w-md-50 img-thumbnail rounded-circle" src={`/assets/images/me/${value.logo}`} alt="Ecole Images" />

                                            <p className="blogtype">{value.location}</p>
                                            <h5>{value.studies[0].title}</h5>
                                            <h4 className="title"><a href="/blog-details">{value.school}</a></h4>
                                            <div className="blog-btn">
                                                <a className="rn-btn text-white" href={`/studies#std${i + 1}`}>Voir</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="view-more-btn mt--60 mt_sm--30 text-center">
                                    <a className="rn-button-style--2 btn-solid" href="/studies"><span>Détails</span></a>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-lg-12">
                                <div className="view-more-btn mt--60 mt_sm--30 text-center">
                                    <div className="section-title text-center mt-5">
                                        <h2>Voir aussi</h2>
                                    </div>
                                </div>
                                <div className="d-flex align-content-end justify-content-center flex-wrap">
                                    <QuickLink name="Compétences" link="/competences"/>
                                    <QuickLink name="Experiences" link="/experiences"/>
                                    <QuickLink name="Projets" link="/projects"/>
                                    <QuickLink name="Formations" link="/studies"/>
                                    <QuickLink name="Certifications" link="/certifications"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* End Blog Area */}

            {/* Start COntact Area */}
            <div id="contact" className="fix">
                <div className="rn-contact-area ptb--120 bg_color--1">
                    <ContactThree contactImages="/assets/images/about/about-9.jpg" contactTitle="Contactez-moi" />
                </div>
            </div>
            {/* End COntact Area */}



           
            {/* Start Back To Top */}
            <div className="backto-top">
                <ScrollToTop showUnder={160}>
                    <FiChevronUp />
                </ScrollToTop>
            </div>

            <FooterTwo />
            {/* End Back To Top */}

        </div >
    )
}

export default Home;
